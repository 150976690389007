.wrapper-select-input {
  .container-select-input {
    position: relative;
    height: 40px;

    input {
      width: 100%;
      height: 100%;
      border-radius: 60px;
      border: none;
      padding: 20px;
      font-size: 12px;
      color: rgb(3, 3, 3);
    }

    .dropdown-select {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }

    .dropdown {
      position: relative;
      height: auto;
      max-height: 250px;
      background: white;
      border-radius: 20px;
      margin: 5px 0px;
      border: 1px solid lightgray;
      z-index: 20;
      overflow: auto;
      box-shadow: -1px 1px 9px 0px lightgray;
      text-align: left;
      line-height: 7dvw;
      padding-left: 5dvw;
      padding-right: 5dvw;
      
      .current-select {
        /* background: rgb(0, 102, 255); */
        color: white;
        margin: 0 0px;
      }

      .dropdown-option {
        color: rgb(0, 0, 0);
        white-space: nowrap;
        padding: 10px 0px 0px 0px;
        font-size: 12px;
      }
    }
  }
}
